import { Component, Input, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { Subscription } from 'rxjs';

//SERVICES
import { FunctionsService } from '../../../services/functions/functions.service';
import { GenericService } from '../../../services/generic/generic.service';
import { CdkStepperModule } from '@angular/cdk/stepper';
@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.css'],
  providers: [{ provide: CdkStepperModule, useExisting: ButtonComponent }],

})
export class ButtonComponent implements OnInit, OnDestroy {
  @Input() structure = null;
  @Input() index: number;
  @Input() param: any = null;
  @ViewChild('matMenuTrigger') trigger: MatMenuTrigger;
  @ViewChild('button') button: ElementRef<HTMLElement>;

  public idTable = null;
  public idRow = null;
  public loaded = false;
  public isOnOver = false;
  public menuTrigger;
  public functionFinish = {};
  public menuVisible: string;
  private menuVisibilitySubscription: Subscription;

  enteredButton = false;
  isMatMenuOpen = false;
  isMenuOpen = false;
  prevButtonTrigger;

  displayMenu = false;

  constructor(public functionsService: FunctionsService,
    public genericService: GenericService,
    private _bottomSheet: MatBottomSheet) {
    this.menuVisible = genericService.lastPosition;
  }

  ngOnInit(): void {
    this.functionFinish[this.index] = { done: true };
    this.menuVisible = 'isNotOpen';
    this.menuVisibilitySubscription = this.genericService.menuVisibilityChange.subscribe(value => {
      this.menuVisible = value;
      if (this.menuVisible == 'out') {
        this.isOnOver = false;
        this.isMenuOpen = false;
      } else {
        this.isOnOver = true;
        this.isMenuOpen = true;
      }
      this.buttonLeave();
    });

    if (this.param !== null) {
      let aux = this.param.split('.')
      if (aux.length == 2) {
        this.idTable = aux[0]
        this.idRow = aux[1]
      }
      else if (aux.length == 3) {
        this.idTable = aux[0] + '.' + aux[1]
        this.idRow = aux[2]
      }
    }
    this.loaded = true;
  }

  ngOnDestroy(): void {
    if (this.menuVisibilitySubscription) {
      this.menuVisibilitySubscription.unsubscribe();
    }
  }

  validateFormStepper() {
    if (this.structure[this.index]["type"] == "nextStep" || this.structure[this.index]["type"] == "previousStep") {
      let idFaStepper = this.genericService.findElementWithId(this.structure[this.index]["stepperId"], true, false, false);
      let idFaStepperParent = idFaStepper["parent"];
      if (idFaStepperParent['type'] == 'vertical-linear' || idFaStepperParent['type'] == 'horizontal-linear') {
        idFaStepper = idFaStepper["child"];
        console.log(idFaStepper, "idFaStepper")
        let label = "";
        if (idFaStepper && idFaStepper["formStep"]) {
          if (idFaStepper["formStep"].invalid) {
            for (const key in idFaStepper["formStep"].controls) {
              if (idFaStepper["formStep"].controls[key].invalid) {
                let labelSplit = key.split("-");
                let field = this.genericService.findElementWithId(labelSplit[0], false, false, true);
                if (field && field["label"]) label = field["label"];
                if (label && label != null && label != "") {
                  this.functionsService.openSnackBar("Falta completar el campo: " + label, 6000, ["red-snackbar"]);
                } else {
                  this.functionsService.openSnackBar("Parece que hay campos sin completar", 6000, ["red-snackbar"]);
                }
                console.error('¡El formulario no es válido!');
                console.error('Campo inválido:', key);
                return false;
              }
            }
            if (idFaStepper["formStep"].errors) {
              console.error('¡El formulario no es válido!');
              console.log('Formulario inválido:', idFaStepper["formStep"].errors, idFaStepper["formStep"]);
            } else {
              // console.error('¡El formulario no es válido for no reason!');
            }
          }
        }
      }
    }
    return true;
  }

  onClickEvent(event: MouseEvent) {
    this.structure[this.index]['isPressingControl'] = event !== undefined && (event.ctrlKey || event.metaKey);
    let validate = this.validateFormStepper();
    if (!validate) return;
    if (this.structure) {
      //this.functionsService.getFunction(eventTrigger['structure']);
      if (this.idTable == null && this.idRow == null) {
        if (this.structure[this.index]['class'] && this.structure[this.index]['class'] != null && this.structure[this.index]['class'] != 0 && this.structure[this.index]['class'] != '' && this.structure[this.index]['class'] != "only-icon") {
          this.functionFinish[this.index]['done'] = false;
        } else {
          this.functionFinish[this.index]['done'] = true;
          this.genericService.actualFunctionFinish = true;
        }
        this.functionsService.getFunction(this.structure[this.index], null, this.functionFinish[this.index]);
        this.genericService.actualFunctionFinish = this.functionFinish[this.index]['done'];
        if (this.structure[this.index]["spinner_timeout"] == 1) {
          setTimeout(() => { // si han pasado 3 segundos y no ha terminado que ponga this.functionFinish a true de nuevo
            this.functionFinish[this.index]['done'] = true;
            this.genericService.actualFunctionFinish = true;
          }, 3000);
        }
      } else {
        this.genericService.tablesActualRows.push({ "id_functional_area": this.idTable, "rowID": this.idRow });
        this.functionsService.getFunction(this.structure[this.index], this.genericService.tableData[this.idTable]['data'][this.idRow], this.idTable)
      }
    }
  }

  over() {
    this.isOnOver = true;
    this.isMenuOpen = true;
  }

  out() {
    setTimeout(() => {
      this.buttonCloseIfNotSubmenu();
    }, 500);
  }

  buttonEnter(trigger) {
    this.menuTrigger = trigger;
    setTimeout(() => {
      if (this.prevButtonTrigger && this.prevButtonTrigger != trigger) {
        this.prevButtonTrigger.closeMenu();
        this.prevButtonTrigger = trigger;
        this.isMatMenuOpen = false;
        this.isMenuOpen = true;
        trigger.openMenu();
      }
      else if (!this.isMatMenuOpen) {
        this.enteredButton = true;
        this.prevButtonTrigger = trigger
        this.isMenuOpen = true;
        trigger.openMenu();
      }
      else {
        this.enteredButton = true;
        this.prevButtonTrigger = trigger
      }
    });
  }

  buttonLeave(trigger = null) {
    if (trigger == null) trigger = this.menuTrigger;
    if (trigger != null && trigger != undefined) {
      setTimeout(() => {
        if (this.enteredButton && !this.isMatMenuOpen && this.menuVisible == 'out' && !this.isOnOver) {
          trigger.closeMenu();
        } if (!this.isMatMenuOpen && this.menuVisible == 'out' && !this.isOnOver) {
          trigger.closeMenu();
        } else {
          this.enteredButton = false;
        }
      }, 500);
    }
    this.buttonCloseIfNotSubmenu();
  }

  buttonCloseIfNotSubmenu() {
    setTimeout(() => {
      if (!this.isMenuOpen && this.menuVisible == "isNotOpen") {
        if (this.menuTrigger != undefined) this.menuTrigger.closeMenu();
      }
    }, 500);
  }

  openBottomSheet(): void {
    this.functionsService.openBottomSheetMenu(this.structure[this.index], this.param);
  }

  displayMenuActivate() {
    this.displayMenu = true;
  }

  displayMenuDesActivate() {
    this.displayMenu = false;
  }
}
