import { Component, OnInit, Input } from '@angular/core';
import { EndpointService } from "app/services/endpoint/endpoint.service";
import { FunctionsService } from 'app/services/functions/functions.service';
import { GenericService } from 'app/services/generic/generic.service';

@Component({
  selector: 'app-code-file',
  templateUrl: './code-file.component.html',
  styleUrls: ['./code-file.component.scss']
})
export class CodeFileComponent implements OnInit {
  
  res = null;
  id_client_script: number;
  name: string;
  index: number;
  struct = null;
  @Input() structure = null;
  code: string = null;
  initCode: string = null;
  public loaded = false;

  editorOptions = {
    theme: 'vs-dark',
    language: 'php',
    automaticLayout: true
  };

  constructor(
    private endpointService: EndpointService,
    public functionsService: FunctionsService,
    public genericService: GenericService,
  ) { }
  
  ngOnInit(): void {
    
    this.id_client_script = this.genericService.findFAWithTableField(3, "client_scripts", "id_client_script", 0)['tmp_value'];
    this.name = this.genericService.findFAWithTableField(3, "client_scripts", "name", 0)['tmp_value'];
    
    this.endpointService.getClientScriptCode(this.id_client_script).subscribe((data) => {
      if (data['response']) {
        this.code = data['response'];
        this.initCode = this.code;
        this.loaded = true;
      }
    });
  }

  saveCode(): void {
    this.endpointService.saveClientScriptCode(this.id_client_script, this.name, this.code).subscribe(() => {
      this.initCode = this.genericService.cloneVariable(this.code);
      this.genericService.openSnackBar("Guardado con éxito", 6000, ["lightgreen-snackbar"]);
    });
  }

}
